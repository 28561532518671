body{
  font-size: 16px;
}
.App {
  text-align: center;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.row{
  display: flex;
  align-items: center;
}
.col{
  width: 50px;
}
.col a{
  display: block;
}
.col svg{ 
  width: 50px;
  height: 50px;
}
.m-t-2{
  margin-top: 2rem;;
}
.p-x-2{
  padding-left: 2rem;
  padding-right: 2rem;
}
.p-r-2{
  padding-right: 2rem;
}
.p-l-2{
  padding-left: 2rem;
}
h1{
  text-transform:uppercase;
  font-weight: 100;
  margin-bottom:0 ;
}